<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Cadastro Tipos de Índices</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 
            <template v-slot:top>
              <BrToolBar
                @search="buscar"
                :configFilter="{
                  listaSelect: item,
                  getItens: getItens,
                  jsonData: false,
                  isStatus,
                }"
                :labelTextField="'Busca por:'" />
            </template>

            <template v-slot:item.indexTypeRecurrence="{ item }">
             <v-chip :color="'blue'" dark> {{item.indexTypeRecurrence }} - {{ typeRecurrence( item.indexTypeRecurrence) }} </v-chip>
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn
                v-if="permiteAcao($route, 'edit')"
                small
                @click="editItem(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2"
              >
                Editar
              </v-btn>

              <v-btn
                v-if="permiteAcao($route, 'delete')"
                class="mr-2"
                small
                @click="deleteItem(item)"
                tile
                outlined 
                :color="variables.colorError"
              >
                Excluir
              </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>

        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// import { errorSnackbar } from '@/core/service/utils'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

export default {
  name: 'TiposIndices',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    activeModal: false,
    headers: [
      {align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      {align: 'start', class: 'table-header', text: 'Nome', value: 'name' },
      {align: 'start', class: 'table-header', text: 'Descrição', value: 'description' },
      {align: 'start', class: 'table-header', text: 'Recorrência', value: 'indexTypeRecurrence' },
      {align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),
  computed: {
    ...mapGetters('tiposIndices', ['listaItens', 'totalItens', 'item']),
    ...mapGetters('roles', ['permiteAcao']),
  },

  mounted () {
    // this.obterItensSelect()
  },

  methods: {
    ...mapActions('tiposIndices', ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'clearItens']),
    typeRecurrence (v) {
      if (v === 'A') return 'Anual'
      if (v === 'M') return 'Mensal'
      return 'Diário'
    }
  }
}
</script>

<style lang="scss">
  @import './../../../assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
